import _ from 'lodash'
import moment from 'moment'

import Organization from './organization'
import REST from '../utils/rest'
import Window from '../utils/window'

export default class Campaign {
  constructor({
    ID,
    accept_donations,
    deadline,
    goal,
    adjustedGoal,
    highlights,
    launch,
    organization,
    product,
    status,
    title,
    pricing,
    inventory,
    sold,
    supporters,
    raised,
  }) {
    this.ID = ID
    this.accept_donations = accept_donations
    this.deadline = moment(deadline)
      .hour(23)
      .minute(59)
      .second(59)
    this.goal = goal
    this.highlights = highlights
    this.launch = moment(launch)
    this.organization = new Organization(organization)
    this.product = product
    this.status = status
    this.title = title
    this.pricing = pricing
    this.inventory = inventory
    this.sold = sold
    this.supporters = supporters
    this.raised = raised
  }

  get active() {
    return moment().isBefore(this.deadline())
  }

  get allowablePurchase() {
    const allowedStatus = !_.includes(
      Campaign.badStatuses,
      _.get(this.status, 'name')
    )
    const launchHasPassed = moment().diff(moment(this.launch)) > 0
    const deadlineHasPassed = moment().diff(moment(this.deadline)) > 0
    if (_.get(this.status, 'key') === 'preorder')
      return allowedStatus && launchHasPassed && !deadlineHasPassed
    return allowedStatus
  }

  static get badStatuses() {
    return ['Draft', 'Scheduled', 'Out of Stock']
  }

  static async get(ID = 1) {
    return REST.post('campaign/get', { ID })
      .then(data => new Campaign(data))
      .catch(() => {
        Window.error('Cannot load campaign details')
      })
  }

  get images() {
    return _.get(this.product, 'images')
  }

  get mainImage() {
    return _.first(this.images)
  }

  get productType() {
    return _.get(this.product, 'description')
  }

  get subscriptionEnabled() {
    return _.has(this.pricing, 'recurring')
  }

  get adjustedGoal() {
    if (this.sold >= this.goal) {
      while (this.sold >= this.goal) {
        this.goal = this.goal + 50

        /* 
        the plan would be to portion it and set the sold/goal ratio to about 75%/25%

        so soemthing like 

        a = this.sold/3
        b = this.sold + a
        this.goal = b rounded to the neareast 50 
        */
      }
      return this.goal
    } else {
      return this.goal
    }
  }

  get tags() {
    return _.sortBy(_.concat([this.status], this.organization.tags), 'rank')
  }

  static timeRemaining(deadline) {
    if (!this.active) return {}

    const duration = moment.duration(deadline.diff(moment()))

    return {
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    }
  }
}
