import _ from 'lodash'
import moment from 'moment'

import REST from '../utils/rest'

export default class Organization {
  constructor({
    ID,
    EIN,
    founded,
    info,
    location,
    mission,
    name,
    tags,
    tiers,
    video,
  }) {
    this.ID = ID
    this.EIN = EIN
    this.founded = moment(founded)
    this.info = info
    this.location = location
    this.mission = mission
    this.name = name
    this.tags = tags
    this.tiers = tiers
    this.video = video
  }

  get age() {
    return Math.abs(this.founded.diff(moment(), 'years'))
  }

  get foundedText() {
    return `${this.founded.format('MMM YYYY')} (${this.age} year${
      this.age > 1 ? 's' : ''
    })`
  }

  get isNonprofit() {
    return !!_.find(this.tags, { key: '501c3' })
  }

  static async all(for_user = false) {
    return REST.promisify([
      {
        ID: '0',
        name: 'Only you',
      },
      {
        ID: '1',
        name: 'Out Youth',
      },
    ])
  }
}
